.navbar {
    background : rgba(0, 0, 0, 0.33);
    padding: 1rem 6rem;
}
.navbar_content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.navbar button {
    text-align: center;
    color: white;
    font-family: MADETOMMY-Bold;
    font-weight: normal;
    font-style: normal;
    width:10vw;
    min-width:150px;
    font-size: 1.5rem;
    text-transform: uppercase;
    cursor: pointer;
    margin-right: 2vw;
    margin-left: 2vw;
}

.navbar_logo {
    text-align: center;
    color: white;
    margin-right: 1vw;
    margin-left: 1vw;
    margin-top: 0.5vw;
    margin-bottom: 0.5vw;
    width: 200px;
    height: 30px;
}

.navbar img {
    position: relative;
    margin: auto;
    height: 30px;
}

.navbar button:hover {
    color: #ccc;
    text-decoration: underline;
    text-decoration-thickness: 0.3rem;
    text-underline-offset: 0.3rem;
}
.navbar_button_active {
    color: #fff;
    text-decoration: underline;
    text-decoration-thickness: 0.3rem;
    text-underline-offset: 0.3rem;
}

.navbar_menu {
    margin-left: 1rem;
    display:none;
    position: relative;
    z-index: 99;
}


.navbar_mobile{
    display:flex;
    justify-content: space-between;
    align-items: center;
}


.navbar_mobile_logo{
    display:none;
    justify-content:center;
}

.navbar_menu svg {
    cursor: pointer;
}

.navbar_menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    text-align: end;
    background: #111f38;
    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
}

.navbar_menu_container button {
    margin: 1rem 0;
}

@media screen and (max-width: 1050px){
    .navbar_content {
        display: none;
    }
    .navbar_menu {
        display: flex;
    }
    .navbar_mobile_logo{
        display: flex;
    }
}

@media screen and (max-width: 700px){
    .navbar {
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 550px){
    .navbar {
        padding: 2rem 4rem;
    }
}


.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
          animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}