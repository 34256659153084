.footer {
    min-height: 15vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: auto;
    flex-wrap: wrap;
    padding: 20px;
    background: #111;
  }
  
  .footer_item {
    text-align: center;
    color: white;
    font-family: MADETOMMY-Bold;
    font-weight: normal;
    font-style: normal;
    margin: 15px;
    width: 24px;
  }
  .footer_item img{
      width: 100%;
  }