.padding_10pt {
    height: 10pt;
}

.padding_20px {
    height: 20px;
}

.release_card {
    display: flex;
    height: 200px;
    align-items: center;
    overflow: hidden;
    -webkit-user-select: none;
    user-select: none;
    border-radius: 5px;
}
.release_card_info {
    position:absolute;
    text-align:center;
    width: 100%;
    pointer-events: none;
    filter: drop-shadow(0.5rem 0.5rem 1rem #222);
    top: 50%;
    transform: translateY(-50%);
}
.release_card_link{
    width: 100%;
    height: 100%;
    position: relative;
    text-align: center;
}
.release_card_art{
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(3px);
    pointer-events: auto;
    transition: all .2s;
    transform: scale(1.3);
}
.release_card_art:hover {
    transform: scale(1.4);
}
.release_card_link:has(.release_card_info:nth-of-type(1):hover) {
    text-decoration: underline;
}

.music_panel {
    margin: 1rem;
    width: 500px;
    max-width: 500px;
}
.Music {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}
.discography {
    height: 640px;
}
.playlist_iframe {
    padding: 0px;
    margin: 0px;
    height: 640px;
}

@media screen and (max-width: 700px){
    .music_panel {
        height: 100%;
        width: 80vw;
        margin: unset;
        margin-top: 1rem;
        height: auto;
    }
    .music_panel .transparent_content_panel {
        height: auto;
        min-width: unset;
    }
    .release_card_art{
        width: 100%;
    }
    .release_card {
        flex-wrap: wrap;
        justify-content: center;
    }
    .release_card p {
        text-align: center;
        margin-top: 1rem;
    }
}