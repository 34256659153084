.Shows {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    max-width: 100vw;
}
.shows_left_panel {
    margin: 1rem;
    margin-bottom: 0;
}

.shows_left_panel img {
    display: block;
    max-height: 900px;
    width: auto;
    height: auto;
    border-radius : 12px;
    -moz-border-radius : 12px;
    -webkit-border-radius : 12px;
}

.shows_right_panel, .shows_panel{
    margin: 1rem;
}

.shows_right_panel .transparent_content_panel {
    max-width: 800px;
    min-width: 600px;
    height: 900px;
}

.shows_panel .transparent_content_panel {
    max-width: 800px;
    min-width: 600px;
}

.content_text {
    text-indent:10pt;
    padding-bottom: 10pt;
    padding-top: 10pt;
    text-align: left;
}



@media screen and (max-width: 700px){
    .shows_left_panel {
        height: 100%;
        width: 80vw;
        margin-right: unset;
        margin-left: unset;
    }
    .shows_left_panel img{
        height: 100%;
        width: 100%;
    }
    .shows_right_panel, .shows_panel{
        margin: unset;
        margin-top: 1rem;
        height: auto;
    }
    .shows_right_panel .transparent_content_panel, .shows_panel .transparent_content_panel {
        height: auto;
        min-width: unset;
    }
}