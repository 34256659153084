@import "fonts/fonts.css";

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body{
  margin: 0%;
  background: radial-gradient(
    farthest-corner at 40px 40px,
    #003b75 0%,
    #0d1324 100%);
  font-family: MADETOMMY;
  font-weight: normal;
  font-style: normal;
  color: #fff;
}

a {
  color: unset;
  text-decoration: none;
}

button {
  background-color: #00000000;
  border-color: #00000000;
}

.AppContent {
  margin: 0vh;
  padding: 2rem;
  min-height: 80vh;
  font-size: calc(10px + 2vmin);
}


.section__padding {
  padding: 4rem 6rem;
}

.section__margin {
  margin: 4rem 6rem;
}






.transparent_content_panel {
  background : rgba(0, 0, 0, 0.33);
  border-radius : 12px;
  -moz-border-radius : 12px;
  -webkit-border-radius : 12px;
  padding: 2rem;
  max-width: 1000px;
  position: relative;
  margin: auto;
}

.content_title{
  font-family : MADETOMMY-Bold;
  font-weight : bold;
  text-transform: uppercase;
  font-size : 32px;
  color : #FFFFFF;
  position: relative;
  text-align: center;
  margin-bottom: 1rem;
}

.content_text, .content_text_centered{
  font-family : MADETOMMY-Thin;
  font-weight : normal;
  font-size : 18px;
  color : #FFFFFF;
  position: relative;
  text-align: justify;
}

.content_text_centered {
  text-align: center;
}

.content_text a, .content_text_centered a {
  color : #A2DDFF;
  text-decoration: underline;
}




/* Different browser sizes support */
@media screen and (max-width: 700px){
  .section__padding {
    padding: 4rem;
  }
  .section__margin {
    margin: 4rem;
  }
}

@media screen and (max-width: 550px){
  .section__padding {
    padding: 4rem 2rem;
  }
  .section__margin {
    margin: 4rem 2rem;
  }
}