.Home {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.home_left_panel{
    height: 600px;
    max-width: 430px;
    display: inline-flex;
    margin: 1rem;
    margin-bottom: 0;
}

.home_left_panel img {
  border-radius : 12px;
  -moz-border-radius : 12px;
  -webkit-border-radius : 12px;
}

.home_right_panel{
    margin: 1rem;
    height: 600px;
    display: inline-flex;
}
.home_right_panel .transparent_content_panel {
    height: 600px;
    max-width: 800px;
    min-width: 600px;
}

.content_text {
    text-indent:10pt;
    padding-bottom: 10pt;
    padding-top: 10pt;
}


@media screen and (max-width: 700px){
    .home_left_panel {
        height: 100%;
        width: 80vw;
        margin-right: unset;
        margin-left: unset;
    }
    .home_left_panel img{
        height: 100%;
        width: 100%;
    }
    .home_right_panel{
        margin: unset;
        height: auto;
        margin-top: 1rem;
    }
    .home_right_panel .transparent_content_panel {
        height: auto;
        min-width: unset;
    }
}